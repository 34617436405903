import React, { useState, useEffect }from 'react';
import { Link } from 'react-router-dom';
import './content_page.css';
import $ from "jquery";
import TimeQuiz from "./datafetch";
import axiosInstance from '../Utilities/axios';
import toast from 'react-hot-toast';


const Assesment= () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [repo, setRepo] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState('');

  const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }


useEffect(() => {
  // Check if the button has been clicked
  if (buttonClicked) {
    getData()
    // Perform an action or fetch data when the button is clicked
  //   $.ajax({
  //       type: "GET",
  //       url: "http://localhost:8000/getdata.inc.php",
  //       dataType: 'JSON',
  //       success(data) {
  //           console.log("data");
  //           console.log(data);
  //           console.log(typeof(data));
  //           // const data_qa = data.filter( (data) => data.section === String(code_filter));
  //           const formattedData = data.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
  //           console.log('TESTING');
  //           console.log(formattedData);
  //           const test = shuffle(formattedData);
  //           const slicedArray = test.slice(0, 50);
  //           setRepo(slicedArray);
  //           setStep(2);
  //       },
  // });
  }
}, [buttonClicked]);

const getData = () => {
  setLoading(true)
  axiosInstance
    .get('/user/getdata')
    .then(function (response) {
      const formattedData = response?.data?.getdata?.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
      const test = shuffle(formattedData);
      const slicedArray = test.slice(0, 150);
      setRepo(slicedArray);
      setButtonClicked(false)
      setStep(2);
      setLoading(false)

    })
    .catch(function (error) {
      setLoading(false)
      console.log(error);
      toast.error(error?.message)
    });
};

const handleButtonClick = () => {
setButtonClicked(true);

};

const ItemList = () => {

return (
  <div>
  <h1 className="section-heading">Test Time</h1>
  <h3>This test is designed to replicate the Final Examination. The test is made up of 150 questions from all chapters. We recommend you try to complete this in under 2 hours. Please read the following instructions carefully before starting:</h3>
        <p>1.	Once you click on an answer, it will be locked in and cannot be changed. Do not click on an answer unless you are sure about your choice.</p>
        <p>2.	There is no option to save and return to the test later. Ensure you are fully prepared and ready to complete the test before you start.</p>
        <p>3.	Although you need 75% to pass the actual test, we recommend aiming for 90% and above on our test.</p>
        <p>Start the test when you are ready</p>
    <button type = "button" className = "btn" onClick={() => handleButtonClick()}>{loading ? 'loading...' : 'Click me'}</button>
  <h3 style={{ marginBottom:100}}></h3>
  </div>
);
}


return (
  <div>
      {step === 0 && <ItemList  />}
      {step === 2  && <TimeQuiz repo={repo} setonStep={setStep}
      sectionInfo={{
        name:'Test',
        code:'Test'
      }}/>}
  </div>
    );
  }






export default Assesment;
