import React from "react";

const Time_Start = ({ onQuizStart, numberOfQuestions }) => {
  return (
    <div className="home-box custom-box">
      <h3> Good Luck! </h3>
      <p> Total number of questions: {numberOfQuestions} </p>
      <button type="button" className="btn" onClick={onQuizStart}>
        {" "}
        Start{" "}
      </button>
    </div>
  );
};

export default Time_Start;
