import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import './content_page.css';
import { useParams, Link } from "react-router-dom";
import Card from '../components/Card';
import projectsData from '../components/project.json';
import subsData from '../components/subtopics.json';

export default function Resources() {
  const sub_sub = subsData.sub_section;
  const params = useParams();
  const subtopic = sub_sub.filter((sub_sub) => sub_sub.id === params.id);
  const test = subtopic[0]
  console.log('***params');
  console.log(params);
  console.log('***params id');
  console.log(params.id);
  console.log('***subtopic');
  console.log(subtopic);
  console.log('***subtopic works?');
  console.log(subtopic[0]);


  const getAnimalsContent = test => {
    let content = [];

    content.push(
      <div key={subtopic[0]?.id} className="grid-table">
        {test?.combo?.map((combo, i) => (
          <Card
            key={i}
            imageUrl={require('./images/Resources.jpg') }
            title={combo?.name}
            body="Review the Study Notes"
            link_title = {combo?.pdf +'/pdf_down'}
          />))}

      </div>);

    return content;
  };


  return (

    <div key={subtopic[0]?.id} className="columnC">
      <div className="pricing_col">
        <h2 className="pricing_header" key={subtopic[0]?.id}>{subtopic[0]?.section + " Resources Page"}</h2>
        {getAnimalsContent(test)}


      </div>
    </div>

  )
}
