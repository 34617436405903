import React, { useState } from 'react';
import {useParams, Link} from "react-router-dom";
import axiosInstance from '../Utilities/axios';
import toast from 'react-hot-toast';

const Time_Question = ({ sectionInfo,data, alldata,onAnswerUpdate, numberOfQuestions, activeQuestion, onSetActiveQuestion,
  shuffleanswers, setShuffleAnswers, availableQuestions, setAvailableQuestions,
  questionCounter, setQuestionCounter,correctAnswers, setCorrectAnswers,results, setResults, onSetStep ,
  attempted, setAttempted, repo}) => {
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [clickedItem, setClickedItem] = useState(null);
  const [oldArray,setArray] = useState([]);
  const [response, setResponse] = useState('');
  const disabledAll = false;
  const [php_data, setPhpData] = useState([]);
  console.log('repo 2');
  console.log(correctAnswers,'correctAnswers');
  console.log(results,'result---------->>>');
  // console.log(typeof(repo));

  // var jsondata = ['{"Site":"Stechies", "Code":"Jquery"}'];
  // // var jsonarray = JSON.parse(repo);
  // const myJSON = JSON.stringify(repo);
  // var userInfo = JSON.parse(repo);
  // console.log("myJson");
  // console.log(myJSON);
  // const split_string = repo.split("{");
  // console.log("split_string");
  // console.log(split_string);

  // let repo_filter = split_string.filter( (split_string) => split_string === 5);
  // console.log("repo filter");
  // console.log(repo_filter);
  // console.log(Object.keys(split_string));


  var randomImages = [
    require('../components/images/square.jpg'),
    require('../components/images/triangle.jpg'),
];


  // const changeHandler=(element) => {
  //   setSelected(element.target.value);
  //   const attempted = 'option already-answered';
  //   const disabledAll = true;
  //   setAttempted(attempted);
  //   const id = element.target.id;
  //   let selectedTag = element ? parseInt(element.target.id, 10) : null;
  //   setClickedItem(selectedTag);
  //   setResults([...results, { selectedTag}]);
  //   const selected = data.options[id];
  //   onAnswerUpdate(prevState => [...prevState, { q: data.question, id: data.id, a: selected }]);
  //   if ((data.options[id] === data.answer)){
  //     console.log("selected changeHandler");
  //     console.log(selected);
  //     console.log("attempted changeHandler");
  //     console.log(attempted);
  //     updateIndicator("correct");
  //     setCorrectAnswers(correctAnswers + 1);
  //     setResponse("Correct answer! " + data.answerShow);
  //     // console.log(response);
  //   } else {
  //     console.log("selected changeHandler");
  //     console.log(selected);
  //     console.log("attempted changeHandler");
  //     console.log(attempted);
  //     updateIndicator("wrong");
  //     setResponse("This is the wrong answer :" + data.answerShow);
  //     // console.log(response);
  //   }
  //   if(error) {
  //     setError('');
  //   }
  // }

  const changeHandler=(element) => {
    setSelected(element.target.value);
    const attempted = 'option already-answered';
    const disabledAll = true;
    setAttempted(attempted);
    const id = element.target.id;
    let selectedTag = element ? parseInt(element.target.id, 10) : null;
    setClickedItem(selectedTag);
    setResults([...results, { selectedTag}]);
    console.log('results');
    console.log(results);
    const selected = data.options[id];
    onAnswerUpdate(prevState => [...prevState, { q: data.question, id: data.id, a: selected }]);
    if ((data.options[id] === data.answer)){
      console.log("selected changeHandler");
      console.log(selected);
      console.log("attempted changeHandler");
      console.log(attempted);
      updateIndicator("correct");
      setCorrectAnswers(correctAnswers + 1);
      setResponse("Correct answer! " + data.answerShow);
      // console.log(response);
    } else {
      console.log("selected changeHandler");
      console.log(selected);
      console.log("attempted changeHandler");
      console.log(attempted);
      updateIndicator("wrong");
      setResponse("This is the wrong answer :" + data.answerShow);
      // console.log(response);
    }
    if(error) {
      setError('');
    }
  }

  const updateIndicator = (markType) =>{
    setArray(oldArray => [...oldArray,markType] );
  }

  const scoreStore = async() => {
    setLoading(true)
    const formdata= new FormData()
    formdata?.append('section_name',sectionInfo?.name)
    formdata?.append('code',sectionInfo?.code)
    formdata?.append('score',Math.round((correctAnswers/numberOfQuestions)*100))
    await axiosInstance
      .post('/user/scoreTest',formdata)
      .then(function (response) {
        if(response?.data?.status){
          toast.success('Quiz Submitted SuccessFully')
          setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.message)
        setLoading(false)
      });
  };

  const nextClickHandler = (element) => {
    if(selected === '') {
      return setError('Please select one option!');
    }
    setSelected('');
    const disabledAll = false;
    const attempted=null;
    console.log("selected next");
    console.log(selected);
    setResponse('');
    setAttempted('');
    console.log("attempted nextbutton");
    console.log(attempted);
    if(questionCounter < numberOfQuestions - 1) {
      // chose random question & set as active question
      const questionIndex = availableQuestions[Math.floor(Math.random() * availableQuestions.length)];
      const index1= availableQuestions.indexOf(questionIndex);
      const newVar=availableQuestions.slice();
      newVar.splice(index1,1);
      setAvailableQuestions(newVar);
      onSetActiveQuestion(questionIndex);
      // lets shuffle the options now
      const options = alldata[questionIndex].options;
      setShuffleAnswers(shuffle(options));
      // add 1 to question counter
      setQuestionCounter(questionCounter +1);
      setClickedItem("");
    }else {
      // const played = data.section;
      // const perc = Math.round((correctAnswers/(alldata.length))*100);
      // console.log("***played");
      // console.log(played);
      // console.log("****perc");
      // console.log(perc);
      // var userdata = {'code':played,'score':perc};
      // console.log(userdata);
      // $.ajax({
      //     type: "POST",
      //     url: "http://localhost:8000/server_score.php",
      //     data: userdata,
      //     success(data) {
      //         setPhpData(data);
      //     },
      // });
      scoreStore()
      onSetStep(3);

    }
  }

  const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  return(
          <div className = "quiz-box custom-box" >
          <div className = "question-number" >
          <span > Question {questionCounter+ 1} of </span> {numberOfQuestions} </div>
                <div className="question-text">
                {data?.question}
                </div>
                <img src={randomImages[data?.img]} />
                <div className="option-container" >
                    {shuffleanswers?.map((option, index) => (
                <div id={index} value={option}
                 className={
                 (index === clickedItem) & (data?.options[index] === data?.answer) ? "option correct" :
                 (index === clickedItem) & (data?.options[index] !== data?.answer) ? "option wrong":
                 "option" }
                onClick={!attempted && changeHandler} key={index} readOnly={attempted}
                  > {option}
                </div>
              ))}
                </div>

          <div className="next-question-btn">
          {error && <div className="has-text-danger">{error}</div>}
          <div className="has-text-danger"> {response} </div>
             <button type="button" className="btn" onClick={nextClickHandler}>{loading ? 'Loading...' : 'Next'}</button>
          </div>
          <div className="answers-indicator">
           {oldArray?.map((indicator, index) => (
            <div id={index} key={index}
            className={ (indicator === "correct") ? "correct" :
             (indicator === "wrong") ? "wrong":
            ""}>
          </div>
         ) )}
          </div>


          </div>
  );
}

export default Time_Question;
