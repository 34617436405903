import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";
 
const Reset = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false);

  const resetSchema = Yup.object().shape({
    token: Yup.string().required("User Name is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });



  const formikReset = useFormik({
    initialValues: {
      token: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: resetSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("token", values?.token);
        formData.append("password", values?.password);
        formData.append("confirm_password", values?.confirm_password);
        const response = await axiosInstance.post("/reset_password", formData);
        console.log(response,'res-------------->>>>')
        if (response?.data?.status) {
            setLoader(false);
            toast.success("Reset Password Successfully",{duration:3000})
            history.push('/Login')
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        toast.error(e?.errors);
      }
    },
  });


    return (
      <div className="container">
        <form className="Auth-form py-4" onSubmit={formikReset.handleSubmit}>
          <div className="Auth-form">
            <h3 className="Auth-form-title">Reset Password</h3>
          
            <div className="form-group mt-3 text-start">
            <label htmlFor="token">Token</label>
            <input
              type="text"
              name="token"
              className="form-control mt-1"
              placeholder="UserName"
              onChange={formikReset.handleChange}
              onBlur={formikReset.handleBlur}
              value={formikReset.values.token}
            />
            {formikReset.touched.token && formikReset.errors.token ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikReset.errors.token}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Password"
              onChange={formikReset.handleChange}
              onBlur={formikReset.handleBlur}
              value={formikReset.values.password}
            />
            {formikReset.touched.password && formikReset.errors.password ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikReset.errors.password}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="confirm_password">Repeat Password</label>
            <input
              type="password"
              name="confirm_password"
              className="form-control mt-1"
              placeholder="Repeat Password"
              onChange={formikReset.handleChange}
              onBlur={formikReset.handleBlur}
              value={formikReset.values.confirm_password}
            />
            {formikReset.touched.confirm_password &&
            formikReset.errors.confirm_password ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikReset.errors.confirm_password}
              </div>
            ) : null}
          </div>

          <div className="text-center py-4" style={{cursor:'pointer'}}>
              
              <span className="link-primary" onClick={()=>{history.push('/Login')}}>
                Sign In
              </span>
            </div>
      
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="form-submit-button" disabled={loader}>{loader ? 'Loading...' :'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );



};

export default Reset;
