/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams, Redirect } from 'react-router-dom';
import { Component } from 'react';
import './content_page.css';
import PdfViewer from "./pdf_viewer";
import subsData from '../components/subtopics.json';
import samplePDFFull from "../components/images/RESPA.pdf";

export default function Programs() {
  const playerRef = React.useRef(null);
  const params = useParams();
  console.log('***params');
  console.log(params);
  console.log('***params id');
  console.log(params.id);
  const sub_sub = subsData.sub_section;
  const subtopic = sub_sub?.filter((sub_sub) => sub_sub?.id === params?.id);
  const test = subtopic[0]
  console.log('***subtopic');
  console.log(subtopic);
  console.log('***subtopic works?');
  console.log(subtopic[0]);


  const getAnimalsContent = test => {
    let content = [];

    content?.push(
      <div key={subtopic[0]?.id} >
        {test?.combo?.map((combo, i) => (

          <div
            key={i}
            style={{ border: 20 }}>
            <video
              id={i}
              className="video-js"
              controls
              preload="auto"
              width="80%"
              height="90%"
              poster=""
              data-setup="{}"
            >
               <source src={`https://apexprimeeducation.com/videos/${combo?.video}`} type="video/mp4" />
              {/* <source src={require("../components/videos/"+combo.video)} type="video/mp4" /> */}
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
              </p>
            </video>
            <PdfViewer pdf={require("../components/images/" + combo.pdf)} />
            <h3 >Time to take the Quiz</h3>
            <a href={"../" + params.id + "/Quiz"} rel="noreferrer" style={{ justifyContent: 'space-between' }}>
              {combo.name}
            </a>
            <h3 style={{ gap: 10, marginBottom: 200 }} >End of Section {i + 1}</h3>
          </div>


        ))}
      </div>
    );

    return content;
  };

  return (
    <>
      <center>

        <div key={subtopic[0]?.id} className="columnC">
          <div className="pricing_col">
            <h2 className="pricing_header" key={subtopic[0]?.id}>{subtopic[0]?.section + " Program Page"}</h2>

            <h3>Click on below link to open
              PDF file in new tab</h3>
            <a href={"../" + params?.id + "/Program/Full"} rel="noreferrer"  >
              Open Full PDF
            </a>
            <h3 style={{ gap: 10, marginBottom: 20 }}></h3>
            {getAnimalsContent(test)}

          </div>
        </div>



      </center>
    </>
  );
}
