import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Footer1 = () => {
  return (
    <section style={{ width: '100%', backgroundColor: '#25272e', color: '#bbb', paddingTop: '60px', paddingBottom: '60px' }}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4" style={{ fontSize: '15px' }}>
            <div>
              <h6 style={{ color: '#fff', marginTop: '10px' }}>Location | Contact</h6>
              <ul className="footer-address">
                <li>Sacramento Ca</li>
                <li>
                  <a href="mailto:Apexprimeeducation@gmail.com" target="_blank" style={{ color: '#bbb', textDecoration: 'none' }}>Apexprimeeducation@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-4" style={{ fontSize: '15px' }}>
            <div>
              <h6 style={{ color: '#fff', marginTop: '10px' }}>Policies</h6>
              <ul className="footer-address">
                <li><Link to='/faq' style={{color:'darkgray'}}>FAQ</Link></li>
                <li><Link to='/term&condition' style={{color:'darkgray'}}>Terms & Conditions</Link></li>
                <li><Link to='/privacy' style={{color:'darkgray'}}>Privacy </Link></li>
                <li><Link to='/refund' style={{color:'darkgray'}}>Refund Policy</Link></li>
                <li> <Link to='/cookie' style={{color:'darkgray'}}>Cookie Policy</Link></li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-4" style={{ fontSize: '15px' }}>
            <div>
              <h6 style={{ color: '#fff', marginTop: '10px' }}>Social</h6>
              <ul className="footer-address">
                <li>
                  <a href="https://www.facebook.com/groups/365568022835859/" target="_blank" style={{ color: '#bbb', textDecoration: 'none' }}>Facebook</a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@apexprimeeducation?is_from_webapp=1&sender_device=pc" target="_blank" style={{ color: '#bbb', textDecoration: 'none' }}>Tiktok</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/apexprimeeducation?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" style={{ color: '#bbb', textDecoration: 'none' }}>Instagram</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@ApexPrimeEducation" target="_blank" style={{ color: '#bbb', textDecoration: 'none' }}>Youtube</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer1;
