import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";
 
const Auth_func = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false);

  const signinSchema = Yup.object().shape({
    users_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    users_pwd: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  const formikSignIn = useFormik({
    initialValues: {
      users_email: "",
      users_pwd: "",
    },
    validationSchema: signinSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("users_email", values?.users_email);
        formData.append("users_pwd", values?.users_pwd);
        const response = await axiosInstance.post("/login", formData);
        console.log(response?.data, "login---->");
        if (response?.status) {
          setLoader(false);
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("userData", JSON.stringify(response?.data?.user));
          sessionStorage.setItem("userData", JSON.stringify(response?.data?.user));
          toast.success("Login Successfully",{duration:2000});
          history.push("/Home");  
          window.location.reload();
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        toast.error(e?.errors);
      }
    },
  });

    return (
      <div className="container py-5">
        <form className="Auth-form py-4" onSubmit={formikSignIn.handleSubmit}>
          <div className="Auth-form">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              New User Sign Up?{" "}
              <span className="link-primary " style={{cursor:'pointer'}} onClick={()=>{history.push('/register')}}>
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3 text-start">
              <label htmlFor="email">Email address</label>
              <input
                className="form-control mt-1"
                placeholder="Email Address"
                type="email"
                name="users_email"
                onChange={formikSignIn.handleChange}
                onBlur={formikSignIn.handleBlur}
                value={formikSignIn.values.users_email}
              />
              {formikSignIn.touched.users_email &&
              formikSignIn.errors.users_email ? (
                <div
                  className="error text-start"
                  style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
                >
                  {formikSignIn.errors.users_email}
                </div>
              ) : null}
            </div>
            <div className="form-group mt-3 text-start">
              <label htmlFor="password">Password</label>
              <input
                className="form-control mt-1"
                placeholder="Password"
                type="password"
                name="users_pwd"
                onChange={formikSignIn.handleChange}
                onBlur={formikSignIn.handleBlur}
                value={formikSignIn.values.users_pwd}
              />
              {formikSignIn.touched.users_pwd &&
              formikSignIn.errors.users_pwd ? (
                <div
                  className="error text-start"
                  style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
                >
                  {formikSignIn.errors.users_pwd}
                </div>
              ) : null}
            </div>
            <div className="text-center py-4" style={{cursor:'pointer'}}>
              
              <span className="link-primary" onClick={()=>{history.push('/forgotpassword')}}>
                Forgot Password
              </span>
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="form-submit-button" disabled={loader}>{loader ? 'Loading...' :'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  
};

export default Auth_func;
