import React from "react";

const Cookie = () => {
  return (
    <>
      <h1 className="section-heading py-5">Cookie Policy</h1>
      <div className="container">
        <h2 className="text-start " style={{fontSize: '20px',fontWeight:600,paddingTop:'10px'}}>
        Apex Prime Education Inc.  Cookie Policy
        </h2>
        <p className="text-start" style={{fontSize: '16px',fontWeight:400,
    lineHeight: '24px'}}>
          At Apex Prime Education Inc., we use cookies on our website to enhance your browsing experience. Cookies are small data files stored on your device that help our website remember your preferences and collect information about your visit. This information is used to improve website functionality, analyze user interaction, and provide a more personalized service. By using our website, you consent to the use of cookies in accordance with this policy. You have the option to manage or disable cookies through your browser settings, although this may affect the functionality of our website.
        </p>
    
      </div>
    </>
  );
};

export default Cookie;
