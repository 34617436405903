import React, { useState, useEffect }from 'react';
import { Link } from 'react-router-dom';
import './content_page.css';
import projectsData from '../components/project.json';
import $ from "jquery";
import TimeQuiz from "./datafetch";
import axiosInstance from '../Utilities/axios';
import toast from 'react-hot-toast';


const Tests= () => {
  const [step, setStep] = useState(0);
  const [btnid, setBtnid] = useState('');

  const proj = projectsData.projects;
  const item = proj[5];
  console.log(item);
  const [repo, setRepo] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState('');
  
console.log(code_filter,'quiz code --------->>>')
  const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }


useEffect(() => {
  // Check if the button has been clicked
  if (buttonClicked) {
    getData()
    // Perform an action or fetch data when the button is clicked
  //   $.ajax({
  //       type: "GET",
  //       url: "https://apexprimeeducation.com/hope_test/PHP/getdata.inc.php",
  //       dataType: 'JSON',
  //       success(data) {
  //           console.log("data");
  //           console.log(data);
  //           console.log(typeof(data));
  //           const data_qa = data.filter( (data) => data.section === String(code_filter));
  //           const formattedData = data_qa.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
  //           console.log('TESTING');
  //           console.log(formattedData);
  //           const test = shuffle(formattedData);
  //           const slicedArray = test.slice(0, 15);
  //           setRepo(slicedArray);
  //           setStep(2);
  //       },
  // });
  }
}, [buttonClicked]);

const getData = () => {
  axiosInstance
    .get('/user/getdata')
    .then(function (response) {
      console.log(response?.data?.getdata,'response------->>>')
      const data_qa = response?.data?.getdata?.filter( (data) => data?.code_structure?.exam === String(code_filter?.code));
      const formattedData = data_qa?.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
      const test = shuffle(formattedData);
      console.log(test,'test-------->>>')
      const slicedArray = test?.slice(0, 100);
      setRepo(slicedArray);
      setButtonClicked(false)
      setStep(2);
     setBtnid('')
    })
    .catch(function (error) {
      console.log(error);
      toast.error(error?.message)
    });
};

const handleButtonClick = (e) => {
// Implement your button click logic here
console.log(`Button clicked for item with ID: ${e}`);
setValue(e);
setButtonClicked(true);

};

const ItemList = (item) => {
  // Using the map function to create a button for each item
  const items = item?.item?.quizs;
  console.log(items);
  const itemButtons = items?.map((quiz, index) => (
    <div key={index} style={{ padding:20}}>
      <p style={{ paddingBottom:0}}>{quiz?.name}</p>
      <button type = "button" className = "btn" onClick={() =>{
          setBtnid(index)
       handleButtonClick(quiz)}
       }>{index === btnid ? 'Loading... ' : 'Click me'}</button>
    </div>
  ));

return (
  <div>
  <h1 className="section-heading">Chapter Exams</h1>
  <h3>Each Chapter has its own practice example. Please read the following instructions carefully before starting:</h3>
        <p>1.	Once you click on an answer, it will be locked in and cannot be changed. Do not click on an answer unless you are sure about your choice.</p>
        <p>2.	There is no option to save and return to the exam later. Ensure you are fully prepared and ready to complete the exam before you start.</p>
        <p>3.	Although you need 75% to pass the actual exam, we recommend aiming for 85% and above on our exam.</p>
        <p>Start the exam when you are ready.</p>
    {itemButtons}

    <h3 style={{ marginBottom:50}}></h3>
    <h3>After you have completed these Exams, it is time to move onto the Test!</h3>
    <a  href={"../Test"} rel="noreferrer" style={{justifyContent: 'space-between'}}>
    Test Page
    </a>
    <h3 style={{ marginBottom:100}}></h3>
  </div>
);
}


return (
  <div>
      {step === 0 && <ItemList item={item} />}
      {step === 2  && <TimeQuiz repo={repo}  sectionInfo={code_filter} setonStep={setStep}/>}
  </div>
    );
  }






export default Tests;
