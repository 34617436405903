import React from 'react';

const Time_Code = ({ onClose, results, data }) => {

results.sort((a, b) => a.id - b.id);

return(
  <div className="modal is-active">
    <div className="modal-background" onClick={onClose}></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Codes for your Study Guide</p>
      </header>
      <section className="modal-card-body content">
        <ul>
          {results.map((result, i) => (
            <li key={i} className="mb-6">
              <p><strong>{result.q}</strong></p>
              {result.a !== data[i].answer && <p className="bg-warning has-text-white p-2">Code: {data[i].section}</p>}
            </li>
          ))}
        </ul>
      </section>
      <div className="modal-footer">
      <button className="close" className="btn btn-secondary" onClick={onClose}>Close</button>
      </div>
    </div>
  </div>
);
}


export default Time_Code;
