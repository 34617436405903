import React, {useState, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import {useParams,Redirect} from 'react-router-dom';
import {Component} from 'react';
import './content_page.css';
import PdfViewer from "./pdf_viewer";
import notes from './notes.json';

export default function Notes() {
const playerRef = React.useRef(null);
const params = useParams();
console.log('***params');
console.log(params);
console.log('***params id');
console.log(params.id);
const notes_sub = notes.notes_section;
// const notes_data = notes_sub.filter( (notes_sub) => notes_sub.id === params.id);
// const notes_data = notes.notes_section;
const test = notes_sub[0];
console.log('***subtopic');
console.log(notes_sub);
console.log('***subtopic works?');
console.log(notes_sub[0]);


const getAnimalsContent = test => {
    let content = [];

      content.push(
        <div key={notes_sub[0]?.id} >
        {test?.sections?.map((section, i) => (

          <div
          key ={i}>

          <PdfViewer pdf={require("./images/" +section?.full_name)} />
         </div>


          ))}
        </div>);

    return content;
  };

  return (
    <>
            <center>

                <div key={notes_sub[0]?.id} className="columnC">
                    <div className="pricing_col">
                  <h2 className="pricing_header" key={notes_sub[0]?.id}>{"Notes Page"}</h2>
                  {getAnimalsContent(test)}

                </div>
                </div>



            </center>
        </>
  );
}
