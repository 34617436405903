import React, { useState, useEffect }from 'react';
import { useParams } from "react-router-dom";
import './content_page.css';
import subsData from '../components/subtopics.json';
import $ from "jquery";
import FlashcardList from "./FlashcardList";
import toast from 'react-hot-toast';
import axiosInstance from '../Utilities/axios';
import { Link } from 'react-router-dom';


const Flashcard_main= () => {
  const [step, setStep] = useState(0);
  const sub_sub = subsData.sub_section;
  const [btnid, setBtnid] = useState('');

  const params = useParams();
  const subfilter = sub_sub?.filter( (sub_sub) => sub_sub?.id === params?.id);
  const item = subfilter[0];
  const [repo, setRepo] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState('');

  const shuffle = (array) => {
    let currentIndex = array?.length,  randomIndex;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
console.log('code_filter',code_filter)

useEffect(() => {
  if (buttonClicked) {
    getData()
  //   $.ajax({
  //       type: "GET",
  //       url: "http://localhost:8000/getdata_flash.inc.php",
  //       dataType: 'JSON',
  //       success(data) {
  //           console.log("data");
  //           console.log(data);
  //           console.log(typeof(data));
  //           if (String(code_filter).length > 6){
  //           const data_qa = data.filter( (data) => data.code === String(code_filter));
  //           const formattedData = data_qa.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
  //           console.log('TESTING');
  //           console.log(formattedData);
  //           const test = shuffle(formattedData);
  //           const slicedArray = test.slice(0, 15);
  //           setRepo(slicedArray);
  //           setStep(2);
  //         }
  //           else {const data_qa = data.filter( (data) => data.section === String(code_filter));
  //           const formattedData = data_qa.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
  //           console.log('TESTING');
  //           console.log(formattedData);
  //           const test = shuffle(formattedData);
  //           const slicedArray = test.slice(0, 15);
  //           setRepo(slicedArray);
  //           setStep(2);
  //         }


  //       },
  // });
  }
}, [buttonClicked]);

const getData = () => {
  axiosInstance
    .get('/user/getdata_flash')
    .then(function (response) {
      console.log(response,'------------->>>')
      const data_qa = response?.data?.getdata_flash?.filter( (data) => data.section === String(code_filter));
      const formattedData = data_qa.map(item => ({  ...item,  options: [item.A, item.B, item.C, item.D],}));
      console.log(formattedData);
      const test = shuffle(formattedData);
      const slicedArray = test.slice(0, 15);
      setRepo(slicedArray);
      setStep(2);
    })
    .catch(function (error) {
      console.log(error);
toast.error(error?.message)
    });
};

const handleButtonClick = (e) => {
 setValue(e);
 setButtonClicked(true);
};

const ItemList = (item) => {
  const items = item?.item?.flashcards;
  console.log(items);
  const itemButtons = items?.map((flashcard,index) => (
    <div key={index} style={{ padding:20}}>
      <p style={{ paddingBottom:0}}>{flashcard?.name}</p>
      <button type = "button" className = "btn" onClick={() => {
         setBtnid(index)
        handleButtonClick(flashcard?.code)}}> {index === btnid ? 'Loading... ' : 'Click me'}</button>
    </div>
  ));

return (
  <div classnName='py-5'>
  <h1 className="section-heading py-5">Flashcard Time</h1>
  <h3>Choose your topic and Flashcard away! All you must do is click on a flash card </h3>
  <h3>and the answer will appear and once you are done with it click again and the flash card will turn back over.</h3>
    {itemButtons}
   
    {/* <Link to={"../"+params.id + "/Program"} rel="noreferrer">
     Return to Program Page
    </Link>
    <br/> */}
    <Link to={"../"+params.id + "/Program"} rel="noreferrer">
    Return to Program section
    </Link>
    

  </div>
);
}


return (
  <div>
      {step === 0 && <ItemList item={item} />}
      {step === 2  && <FlashcardList repo={repo} />}
  </div>
    );
  }






export default Flashcard_main;
