// import React from "react";
// import { createGlobalStyle } from "styled-components";
// import { Link } from "react-router-dom";
// import { createPdfFromHtml } from "./pdf_logic";
// import {useParams} from "react-router-dom";
// import subsData from '../components/subtopics.json';
// const Global = createGlobalStyle`
//   /* paper.css */
//   https://github.com/cognitom/paper-css
//
//   /* @page { margin: 0 } */
//   #print {
//     margin: 0;
//     font-family: "IPAexGothic", sans-serif;
//   }
//   .sheet {
//     margin: 0;
//     overflow: hidden;
//     position: relative;
//     box-sizing: border-box;
//     page-break-after: always;
//   }
//
//
//   /** Paper sizes **/
//   #print.A3               .sheet { width: 297mm; height: 419mm }
//   #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
//   #print.A4               .sheet { width: 210mm; height: 296mm }
//   #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
//   #print.A5               .sheet { width: 148mm; height: 209mm }
//   #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
//   #print.letter           .sheet { width: 216mm; height: 279mm }
//   #print.letter.landscape .sheet { width: 280mm; height: 215mm }
//   #print.legal            .sheet { width: 216mm; height: 356mm }
//   #print.legal.landscape  .sheet { width: 357mm; height: 215mm }
//
//   /** Padding area **/
//   .sheet.padding-10mm { padding: 10mm }
//   .sheet.padding-15mm { padding: 15mm }
//   .sheet.padding-20mm { padding: 20mm }
//   .sheet.padding-25mm { padding: 25mm }
//
//   /** For screen preview **/
//   @media screen {
//     body {
//       background: #E0E0E0;
//       height: 100%;
//     }
//     .sheet {
//       background: #FFFFFF;
//       /* margin: 5mm auto; */
//       padding: 5mm 0;
//     }
//   }
//
//   /** Fix for Chrome issue #273306 **/
//   @media print {
//     #print.A3.landscape            { width: 420mm }
//     #print.A3, #print.A4.landscape { width: 297mm }
//     #print.A4, #print.A5.landscape { width: 210mm }
//     #print.A5                      { width: 148mm }
//     #print.letter, #print.legal    { width: 216mm }
//     #print.letter.landscape        { width: 280mm }
//     #print.legal.landscape         { width: 357mm }
//   }
// `;
//
//
// export class PrintPage extends React.Component {
//   printContent;
//   render() {
//     return (
//       <>
//         <Global />
//         <button onClick={this.handleClick}>Download</button>
//         <div id="print" className="A4">
//           <Sheet />
//           <div style={{ position: "fixed", top: "200vh" }}>
//             <div
//               ref={el => {
//                 this.printContent = el;
//               }}
//             >
//               <Sheet />
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
//
//   handleClick = () => {
//     createPdfFromHtml(this.printContent);
//   };
// }
//
// const Sheet = () => {
//   const sub_sub = subsData.sub_section;
//   const params = useParams();
//   return (
//     <div className="sheet padding-10mm">
//     <h1>{params.id}</h1>
//       <div>Here is all the information for {params.id}</div>
//       <div>please download</div>
//     </div>
//   );
// };
import React, {useState, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import {useParams,Redirect} from 'react-router-dom';
import {Component} from 'react';
import './content_page.css';
import PdfViewer from "./pdf_viewer";
// import samplePDFFull from "..";


export default function Programs_Links() {
  const params = useParams();
  console.log('***params');
  console.log(params);

  return (
    <>
            <center>
                <h1>Welcome {params.id}</h1>
                <PdfViewer pdf={require("../components/images/"+params.id)}/>

            </center>
        </>
  );
}
