import React from "react";

const Refund = () => {
  return (
    <>
      <h1 className="section-heading py-5">Refund Policy</h1>
      <div className="container">
        <h2 className="text-start " style={{fontSize: '20px',fontWeight:600,paddingTop:'10px'}}>
          Refund Policy for Apex Prime Education Inc.
        </h2>
        <p className="text-start" style={{fontSize: '16px',fontWeight:400,
    lineHeight: '24px'}}>
          At Apex Prime Education Inc., we aim to provide exceptional service
          and understand that circumstances may change for our clients. To
          address this, we have established a clear and fair refund policy. This
          policy outlines the conditions under which refunds are available,
          reflecting the stages of progress in our service delivery. Our
          commitment is to ensure transparency and understanding regarding our
          refund process.
        </p>
        <h2 className="text-start " style={{fontSize: '20px',fontWeight:600,paddingTop:'10px'}}>
        Refund Policy
        </h2>
        <p className="text-start" style={{fontSize: '16px',fontWeight:400,
    lineHeight: '24px'}}>
        We understand things happen. If you require a refund, we kindly ask that you contact us directly as soon as possible to discuss your specific situation. Please be aware that if you have already accessed the course materials, partial refunds may be issued to cover processing fees and credit card charges. Our goal is to ensure a fair and satisfactory resolution for all parties involved. Thank you for your understanding.
        </p>
        <h2 className="text-start " style={{fontSize: '20px',fontWeight:600,paddingTop:'10px'}}>
        Processing Time for Refunds
        </h2>
        <p className="text-start" style={{fontSize: '16px',fontWeight:400,
         lineHeight: '24px'}}>
         7-Day Refund Policy for Apex Prime Education Inc.: We at Apex Prime Education Inc. understand the importance of customer satisfaction. Refund requests must be submitted to our customer service team within the 7-day period, and the refund will be processed using the original payment method. We encourage customers to carefully review this policy, as it is subject to change, and contact us for any clarifications or assistance. Please email us at Apexprimeeducation@gmail.com.
        </p>
      </div>
    </>
  );
};

export default Refund;
