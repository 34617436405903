import React, { useEffect, useState, Component} from 'react';
import { useHistory } from "react-router-dom";
import subsData from '../components/subtopics.json';
import test_quiz from '../components/path_info.json';
import axiosInstance from '../Utilities/axios';


const Time_End = ({ results, data, numberOfQuestions,onReset, onAnswersCheck, onCode, correctAnswers, repo,setonStep}) => {
const [correct, setCorrectAnswers] = useState(0);
const [test, setTest] = useState("");
const [response, setResponse] = useState('');
const played = data[0].section;
const perc = Math.round((correctAnswers/numberOfQuestions)*100);
const path_info = test_quiz.path;
console.log(path_info);

console.log(results,'results-------->>>');
console.log(data,'data-------->>>');
  const history = useHistory();

  const onquizpage = () =>{
    setonStep(0);
  }

  // const getData = () => {
  //   axiosInstance
  //     .get('/user/scoreTest')
  //     .then(function (response) {
  //       console.log(response,'time end-------->>>>')
  //       const find_path = response?.data?.score?.filter( (path_info) => path_info?.code === played);
  //       console.log(find_path,'find path');
  //       let path = find_path[0]?.path;
  //       if (path=="/Test"){window.location.reload();}
  //       else {
  //       history.push(path);}
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       toast.error(error?.message)
  //     });
  // };



  return(
    <div className="result-box custom-box">
        <h1>Quiz Result</h1>
        <table>
        <tbody>
           <tr>
               <td>Total Question</td>
               <td><span className="total-question"></span>{numberOfQuestions}</td>
           </tr>
           <tr>
               <td>Correct</td>
               <td><span className="total-correct">{correctAnswers}</span></td>
           </tr>
           <tr>
               <td>Wrong</td>
               <td><span className="total-wrong"></span>{numberOfQuestions - correctAnswers}</td>
           </tr>
           <tr>
               <td>Percentage</td>
               <td><span className="percentage"></span>{Math.round((correctAnswers/numberOfQuestions)*100)}% </td>
           </tr>
        </tbody>
        </table>
        <button className="button btn"  onClick={onAnswersCheck}>Check your answers</button>
        <button className="button btn"  onClick={()=>{onquizpage()}}>Return to Quiz Page</button>
        {/* <button className="button btn"  onClick={onCode}>Codes for Study</button> */}
        <div className="has-text-danger"> {test} </div>
    </div>
  );
}

export default Time_End;
