import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import throttle from 'lodash/throttle';
import "./pdf_css.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdf }) => {
  const [initialWidth, setInitialWidth] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfWrapper = useRef();

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div>
      <div className="controls">
        <button className="btn" onClick={prevPage} disabled={pageNumber === 1}>
          Prev
        </button>
        <button className="btn" onClick={nextPage} disabled={pageNumber === numPages}>
          Next
        </button>
      </div>
      <div id="pdfWrapper" style={{ width: '100vw' }} ref={pdfWrapper}>
      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container"
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
    </div>
  );
};

export default PdfViewer;
