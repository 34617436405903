import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./content_page.css";
import subsData from "../components/subtopics.json";
import $ from "jquery";
import TimeQuiz from "./Random_Quiz";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";

const Quiz = () => {
  const [step, setStep] = useState(0);
  const [btnid, setBtnid] = useState('');
  const sub_sub = subsData.sub_section;
  const params = useParams();
  console.log(params?.id,'-------------- route name')
  const subfilter = sub_sub?.filter((sub_sub) => sub_sub?.id === params?.id);
  const item = subfilter[0];
  console.log(subfilter,'subfilter--------->>>>');
  console.log(item,'question--------->>>>');
  // const item = proj[5];
  const [repo, setRepo] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState("");
console.log(code_filter,'-----------code filter')
console.log(code_filter.length,'code length-------->>>')

  const shuffle = (array) => {
    if (!Array.isArray(array) || array.length === 0) {
      return []; // Return an empty array or handle the invalid input appropriately
    }

    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    if (buttonClicked) {
      getData()     
    }
  }, [buttonClicked]);

  const getData = () => {
    axiosInstance
      .get('/user/getdata')
      .then(function (response) {
        // if (String(code_filter).length > 6) {
          const data_qa = response?.data?.getdata?.filter(
            (data) => data?.code_structure?.section === String(code_filter?.code)
          );
          const formattedData = data_qa.map((item) => ({
            ...item,
            options: [item.A, item.B, item.C, item.D],
          }));
          const test = shuffle(formattedData);
          const slicedArray = test.slice(0, 150);
          setRepo(slicedArray);
          setBtnid('')
          setButtonClicked(false)
          setStep(2);
      })
      .catch(function (error) { 
        console.log(error);
        toast.error(error?.message)
      });
  };

  const handleButtonClick = (e) => {
    setValue(e);
    
  };
  console.log(buttonClicked,'button state')

  const ItemList = (item) => {
    const items = item?.item?.quizs;
    const itemButtons = items?.map((quiz, index) => (
      <div key={index} style={{ padding: 20 }}>
        <p style={{ paddingBottom: 0 }}>{quiz.name}</p>
        <button
          type="button"
          className="btn"
          onClick={() =>{
            setBtnid(index)
            setButtonClicked(true);
            handleButtonClick(quiz)
          } }
        >
          {index === btnid ? 'Loading... ' : 'Click me'}
        </button>
      </div>
    ));

    return (
      <div>
        <h1 className="section-heading">Quiz Time</h1>
        <h3>Each Module has its own practice quiz. Please read the following instructions carefully before starting:</h3>
        <p>1.	Once you click on an answer, it will be locked in and cannot be changed. Do not click on an answer unless you are sure about your choice.</p>
        <p>2.	There is no option to save and return to the quiz later. Ensure you are fully prepared and ready to complete the quiz before you start.</p>
        <p>3.	Although you need 75% to pass the actual test, we recommend aiming for 85% and above on our quiz.</p>
        <p>Start the quiz when you are ready.</p>
        {itemButtons}

        <a href={"../" + params.id + "/Program"} rel="noreferrer">
          Return to Program Page
        </a>
        <br padding="100px"></br>
      </div>
    );
  };

  return (
    <div>
      {step === 0 && <ItemList item={item} />}
      {step === 2 && <TimeQuiz repo={repo} sectionInfo={code_filter} setonStep={setStep}/>}
    </div>
  );
};

export default Quiz;
