import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";
import Footer1 from "./Footer1";

const Register = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false);

  const signupSchema = Yup.object().shape({
    users_uid: Yup.string().required("User Name is required"),
    users_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    users_pwd: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("users_pwd"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formikSignUp = useFormik({
    initialValues: {
      users_uid: "",
      users_email: "",
      users_pwd: "",
      confirm_password: "",
    },
    validationSchema: signupSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        const response = await axiosInstance.post("/register", formData);
        if (response?.data?.status) {
            setLoader(false)
            toast.success(response?.data?.message,{duration:3000})
            history.push('/Login')
        }
        else{
          toast.error(response?.data?.response, { duration: 5000 });
          history.push('/subscription')
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        const errorResponse = e?.errors;

        if (typeof errorResponse === "string") {
          toast.error(errorResponse, { duration: 5000 });
        } else if (typeof errorResponse === "object") {
          // Specific field errors
          const errorMessages = Object.values(errorResponse).flat();
          errorMessages.forEach((error) => {
            toast.error(error, { duration: 2000 });
            if(error === 'The selected users email is invalid.'){
              history.push('/subscription')
            }
          });
        } else {
          toast.error(e?.response || "An unknown error occurred", { duration: 5000 });
          if(e?.response === 'you already have an account and you can login'){
            history.push('/Login')
          }
          // Fallback for unknown error format
        }
      }
    },
  });


  return (
  <>
    <div className="container py-5">
      <form className="Auth- py-4" onSubmit={formikSignUp.handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={()=>{history.push('/Login')}}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="users_uid">User Name</label>
            <input
              type="text"
              name="users_uid"
              className="form-control mt-1"
              placeholder="UserName"
              onChange={formikSignUp.handleChange}
              onBlur={formikSignUp.handleBlur}
              value={formikSignUp.values.users_uid}
            />
            {formikSignUp.touched.users_uid && formikSignUp.errors.users_uid ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikSignUp.errors.users_uid}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="users_email">Email address</label>
            <input
              type="email"
              name="users_email"
              className="form-control mt-1"
              placeholder="Email Address"
              onChange={formikSignUp.handleChange}
              onBlur={formikSignUp.handleBlur}
              value={formikSignUp.values.users_email}
            />
            {formikSignUp.touched.users_email &&
            formikSignUp.errors.users_email ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikSignUp.errors.users_email}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="users_pwd">Password</label>
            <input
              type="password"
              name="users_pwd"
              className="form-control mt-1"
              placeholder="Password"
              onChange={formikSignUp.handleChange}
              onBlur={formikSignUp.handleBlur}
              value={formikSignUp.values.users_pwd}
            />
            {formikSignUp.touched.users_pwd && formikSignUp.errors.users_pwd ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikSignUp.errors.users_pwd}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3 text-start">
            <label htmlFor="confirm_password">Repeat Password</label>
            <input
              type="password"
              name="confirm_password"
              className="form-control mt-1"
              placeholder="Repeat Password"
              onChange={formikSignUp.handleChange}
              onBlur={formikSignUp.handleBlur}
              value={formikSignUp.values.confirm_password}
            />
            {formikSignUp.touched.confirm_password &&
            formikSignUp.errors.confirm_password ? (
              <div
                className="error text-start"
                style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
              >
                {formikSignUp.errors.confirm_password}
              </div>
            ) : null}
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="form-submit-button" disabled={loader}>{loader ? 'Loading...' :'Submit'}
            
            </button>
          </div>
        </div>
      </form>
    </div>
    <Footer1/>
  </>
  );
};

export default Register;
