import React, { useState, useEffect }from 'react';
import {Component} from 'react';
import './content_page.css';
import Card from '../components/Card';
import projectsData from '../components/project.json';


const GMK = () => {
  const proj = projectsData.projects;
  const item = proj[1];
return (
  <div className="container-fluid">
    <div className="grid-table">
    {item.options.map((option, index) => (
        <Card
        key ={index}
        imageUrl={require('./images/'+option.code+'.jpg') }
      title={option?.name || option}
      body=""
      link_title = {`${option?.code || option}/Landing`}
      />))}

    </div>

  </div>
  )

}
export default GMK;
