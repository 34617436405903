import React from "react";

const FAQ = () => {
  return (
    <>
      <h1 className="section-heading py-5">FAQ</h1>
      <div className="container">
        <h6
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 500, paddingTop: "10px" }}
        >
          Apex Prime Education Inc. FAQ
        </h6>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          I need to speak to someone, what do I do?
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          If you need to get in touch with us, please don't hesitate to reach
          out via email. We are committed to responding to your queries and
          concerns as swiftly as possible. You can email us at
          Apexprimeeducation@gmail.com, and our team will make sure to get back
          to you promptly.{" "}
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          How do I sign up for a course?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Signing up for a course is simple. Visit our website, select payment,
          enter your email, make a payment, create your login, and then sign in
          and start. Follow the instructions to complete your registration.
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          What payment methods do you accept?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          We accept various payment methods with our processing company Stripe
          including credit/debit cards. You can select your preferred payment
          method during the checkout process.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Can I get a refund if I am not satisfied with the course?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Yes, we offer a 7-day refund policy. If you are not satisfied with the
          course, you can request a refund within 7 days of your purchase.
          Please refer to our Refund Policy section for more details.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          How do I access my course materials?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Once you have enrolled in a course you can access your course
          materials by logging into your account on our website.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Are there any prerequisites for enrolling in a course?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Our courses are designed to help you pass your exam, but they do not
          fulfill the actual education requirements. Please check your exam
          requirements to ensure you meet them before enrolling in our courses.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          How long do I have access to the course materials?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          You will have access to the course materials for 90 days from the date
          of enrollment. This allows ample time to review and complete the
          course content.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Can I get a certificate upon completion of a course?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          No, we do not provide a certificate upon completion as this is an
          educational course designed to help you pass the exams. The focus is
          on preparing you thoroughly for your certification exams.{" "}
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          What if I have technical issues accessing the course?
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          If you experience any technical issues, please contact our support
          team at Apexprimeeducation@gmail.com. We will assist you in resolving
          any issues as quickly as possible.
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Do you offer any discounts or promotions?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Yes, we periodically offer discounts and promotions. Please follow us
          on social media to stay updated on our latest offers.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Can I take more than one course at a time?{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Absolutely! You can enroll in multiple courses simultaneously and
          access all the materials as needed.
        </p>
      </div>
    </>
  );
};

export default FAQ;
