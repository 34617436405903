import React, { useEffect, useState, Component} from 'react';
import './content_page.css';
import $ from "jquery";
import axiosInstance from '../Utilities/axios';
import toast from 'react-hot-toast';
// import projectsData from '../components/project.json';

const Results = () => {
  const [step, setStep] = useState(0);
  // const proj = projectsData.projects;
  // const item = proj[5];
  const item = "";
  const [repo, setRepo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const username = userData?.username;
  console.log(userData,'userData')
  const userid = userData?.id;
  const [score, setScore] = useState("");


  useEffect(() => {
    // Check if the button has been clicked
    if (buttonClicked) {
      getData()
      // Perform an action or fetch data when the button is clicked
    //   $.ajax({
    //       type: "GET",
    //       url: "http://localhost:8000/getdata_compscore.inc.php",
    //       dataType: 'JSON',
    //       success(data) {
    //           console.log("data");
    //           console.log(data);
    //           console.log(typeof(data));
    //           // const data_qa = data.filter( (data) => data.section === String(code_filter));
    //           const data_qa2 = data.filter( (data) => data.users_id === String(userid));
    //           console.log(data_qa2);
    //           const formattedData = data_qa2.map(item => ({  ...item}));
    //           console.log(formattedData);
    //           console.log(typeof(formattedData));
    //           setScore(formattedData);
    //           console.log(score);
    //           setStep(2);
    //       },
    // });
    }
  }, [buttonClicked]);

  const getData = () => {
    setLoading(true)
    Promise.all([
      axiosInstance.get('/user/getdata_compscore'),
      axiosInstance.get('/user/getdata_exam'),
      axiosInstance.get('/user/scoreTest')
    ])
      .then(function ([response1, response2,response3]) {
        // Process first API response
        console.log(response1?.data?.compscore, 'response1?.data?.compscore');
        const data_qa2 = response1?.data?.compscore?.filter((data) => data?.users_id === userid);
        const formattedData1 = data_qa2.map(item => ({ ...item }));

        const data_another = response2?.data?.examscore?.filter((data) => data?.users_id === userid);
        const formattedData2 = data_another.map(item => ({ ...item }));

        const testData = response3?.data?.score?.filter((data) => data?.users_id === userid);
        const formattedData3 = testData.map(item => ({ ...item }));
        console.log(formattedData3, 'formattedData3');
        
        const combinedData = [...formattedData1, ...formattedData2, ...formattedData3];
        setLoading(false)
        setScore(combinedData);
        setStep(2);
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
        toast.error(error?.message);
      });
  };
  

  const handleButtonClick = () => {
  setButtonClicked(true);
  };

const ItemList = () => {
return (
  <div>
  {/* <h1 className="section-heading">Practice Exams</h1> */}
  <h3 className='py-5'>Click below to reveal your current scores</h3>
    <button type="button" className="btn" onClick={() => handleButtonClick()}>{loading ? 'Loading...' : 'Click me'}</button>
  </div>
);
}

const Scores = (repo) => {

const items = repo.repo;

return (
  <div className='container'>
  {/* <h1 className="section-heading">Practice Exams</h1> */}
  <h3 className='py-5'>Below you will see your attempts and average scores.  Each score has a corresponding name and code so you know <br/>which quiz, exam,or test you have taken. Your goal should be to have an average score of at least 85% </h3>
  <div className="result-box custom-box">
  <table>
  <thead>
    <tr>
      <th>Section Name</th>
      <th>Code</th>
      <th>Total Attempt</th>
      <th>Average</th>
    </tr>
  </thead>
  <tbody>
    {items.map((item, sectionIndex) => (
        <tr key={`${sectionIndex}`}>
          <td>{item?.code_desc}</td>
          <td>{item?.section}</td>
          <td>{item?.total_attempt}</td>
          <td>{item?.avg_score?.toFixed(1)}%</td>
        </tr>
      ))}
  </tbody>
</table>

  </div>
</div>
);}


return (
  <div>
      {step === 0 && <ItemList item={item} />}
      {step === 2  && <Scores repo={score} />}
  </div>
    );
}
export default Results;
