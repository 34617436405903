import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import './content_page.css';
import { useParams, Link } from "react-router-dom";
import Card from '../components/Card';
// import projectsData from '../components/project.json';
// import subsData from '../components/subtopics.json';

export default function Landing_Substopic() {
  // const sub_sub = subsData.sub_section;
  const params = useParams();
  // const subtopic = sub_sub.filter( (sub_sub) => sub_sub.id === params.id);
  // const test = subtopic[0];
  console.log('***params');
  console.log(params);
  console.log('***params id');
  console.log(params.id);
  // console.log('***subtopic');
  // console.log(subtopic);
  // console.log('***subtopic works?');
  // console.log(subtopic[0]);




  return (


    <div className="container-fluid">
      <div className="grid-table">
        <Card
          imageUrl={require('../components/images/Resources.jpg')}
          title={params.id + " Resources"}
          body="Find all Slides Notes here"
          link_title={params.id + "/Resources"}
        />
        <Card
          imageUrl={require('../components/images/Program.jpg')}
          title={params.id + " Program"}
          body="Find all Slides & Videos"
          link_title={params.id + "/Program"}
        />
        <Card
          imageUrl={require('../components/images/Videos.jpg')}
          title={params.id + " Videos"}
          body="Watch all Section Content here"
          link_title={params.id + "/Videos"}
        />
        <Card
          imageUrl={require('../components/images/Quiz.jpg')}
          title={params.id + " Section Quizes"}
          body="Test your Section Knowledge here"
          link_title={params.id + "/Quiz"}
        />
        <Card
          imageUrl={require('../components/images/Resources.jpg')}
          title={params.id + " Learning Module"}
          body="Extra Learning Resources"
          link_title={params.id + "/Learning_Module"}
        />

      </div>
    </div>


  )
}
